<template>
  <h1 class="text-center">ShoppingCard Pages</h1>
</template>

<script>
export default {
  name: "ShoppingCardPages"
}
</script>

<style scoped>

</style>